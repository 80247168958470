import { useEffect } from "react";
import type { FileUpload } from "@jobber/components/InputFile";
import { useIntl } from "react-intl";
import { messages } from "~/components/ImageUpload/messages";

interface HookParams {
  readonly files: FileUpload[];
  readonly attachToDOM: boolean | undefined;
  readonly required: boolean | undefined;
  readonly setUploadError: (error: string) => void;
}

const WORK_REQUEST_FORM_ID = "new_work_request";

export function useLegacyForm(props: HookParams) {
  const { formatMessage } = useIntl();

  // eslint-disable-next-line max-statements
  useEffect(() => {
    if (!props.attachToDOM) {
      return;
    }

    // filter any partial uploads before submitting
    const attachments = props.files
      .filter(file => file.progress === 1.0)
      .map(file => ({ key: file.key }));

    if (attachments.length > 0) {
      const existingInput = document.getElementById("attachments");

      /* istanbul ignore next -- @preserve */
      if (existingInput) {
        existingInput.remove();
      }

      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("id", "attachments");
      input.setAttribute("name", "attachments");
      input.setAttribute("value", JSON.stringify(attachments));

      const form = document.getElementById(WORK_REQUEST_FORM_ID);
      form?.appendChild(input);
    } else if (props.required) {
      const handler = (event: PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();
        props.setUploadError(formatMessage(messages.uploadRequired));
      };
      const submitButton = document.getElementsByClassName("js-submitForm")[0];
      submitButton?.addEventListener("click", handler);

      return () => submitButton?.removeEventListener("click", handler);
    }
  }, [props, formatMessage]);
  return;
}
