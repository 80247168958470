import { useLocation } from "react-router-dom";

export function useUTMParameters() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium") ?? undefined;
  const utmCampaign = searchParams.get("utm_campaign") ?? undefined;
  const utmTerm = searchParams.get("utm_term") ?? undefined;
  const utmContent = searchParams.get("utm_content") ?? undefined;

  if (!utmSource) {
    return undefined;
  }

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
  };
}
