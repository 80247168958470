import React from "react";
import { DatePicker } from "@jobber/components/DatePicker";
import { Spinner } from "@jobber/components/Spinner";
import classNames from "classnames";
import styles from "./styles.module.css";

type SelfServeBookingDatePickerProps = Parameters<typeof DatePicker>[0] & {
  inline: true;
  loading: boolean;
};

export function SelfServeBookingDatePicker({
  loading,
  ...DatePickerProps
}: SelfServeBookingDatePickerProps) {
  return (
    <div
        className={classNames(
          styles.onlineBookingDatePicker,
          styles.specificity,
          loading ? styles.loading : {},
        )}
      >
        {loading && (
          <div className={styles.loadingOverlay}>
            <Spinner />
          </div>
        )}
        <DatePicker minDate={new Date()} {...DatePickerProps} />
      </div>
  );
}
